<template>
  <div>
    <!-- 题目 / qu_metatype 1纯文字 2富文本 3图片链接-->
    <!-- 配伍题 -->
    <div>
      <div class="question_title" v-show="data.qu_metatype == 1">
        {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.
        {{ data.qu_content }}
      </div>
      <div
        class="question_title"
        v-show="data.qu_metatype == 2"
        style="display: flex"
      >
      {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.
        <p style="max-width:1050px;" v-html="data.qu_content"></p>
      </div>
      <div v-show="data.qu_metatype == 3">
        {{ type == 2 ? data.qu_list[0].isNum + 1 : is_num }}.
        <img style="max-width:1050px;" :src="data.qu_content" alt="" />
      </div>
      <div class="radio_box compatimy">
        <ul>
          <!-- A -->
          <li v-if="data.an_item_a_metatype && data.an_item_a_metatype > 0">
            <div class="left">
              <!-- <button>A</button> -->
              A.
              <p v-if="data.an_item_a_metatype == 1">
                {{ data.an_item_a_content }}
              </p>
              <p
                v-if="data.an_item_a_metatype == 2"
                v-html="data.an_item_a_content"
              ></p>
              <img
                v-show="data.an_item_a_metatype == 3"
                :src="data.an_item_a_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_a_content"
              target="_blank"
              v-show="data.an_item_a_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- B -->
          <li v-if="data.an_item_b_metatype && data.an_item_b_metatype > 0">
            <div class="left">
              <!-- <button>B</button> -->
              B.
              <p v-if="data.an_item_b_metatype == 1">
                {{ data.an_item_b_content }}
              </p>
              <p
                v-if="data.an_item_b_metatype == 2"
                v-html="data.an_item_b_content"
              ></p>
              <img
                v-show="data.an_item_b_metatype == 3"
                :src="data.an_item_b_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_b_content"
              target="_blank"
              v-show="data.an_item_b_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- C -->
          <li v-if="data.an_item_c_metatype && data.an_item_c_metatype > 0">
            <div class="left">
              <!-- <button>C</button> -->
              C.
              <p v-if="data.an_item_c_metatype == 1">
                {{ data.an_item_c_content }}
              </p>
              <p
                v-if="data.an_item_c_metatype == 2"
                v-html="data.an_item_c_content"
              ></p>
              <img
                v-show="data.an_item_c_metatype == 3"
                :src="data.an_item_c_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_c_content"
              target="_blank"
              v-show="data.an_item_c_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- D -->
          <li v-if="data.an_item_d_metatype && data.an_item_d_metatype > 0">
            <div class="left">
              <!-- <button>D</button> -->
              D.
              <p v-if="data.an_item_d_metatype == 1">
                {{ data.an_item_d_content }}
              </p>
              <p
                v-if="data.an_item_d_metatype == 2"
                v-html="data.an_item_d_content"
              ></p>
              <img
                v-show="data.an_item_d_metatype == 3"
                :src="data.an_item_d_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_d_content"
              target="_blank"
              v-show="data.an_item_d_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- E -->
          <li v-if="data.an_item_e_metatype && data.an_item_e_metatype > 0">
            <div class="left">
              <!-- <button>E</button> -->
              E.
              <p v-if="data.an_item_e_metatype == 1">
                {{ data.an_item_e_content }}
              </p>
              <p
                v-if="data.an_item_e_metatype == 2"
                v-html="data.an_item_e_content"
              ></p>
              <img
                v-show="data.an_item_e_metatype == 3"
                :src="data.an_item_e_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_e_content"
              target="_blank"
              v-show="data.an_item_e_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
          <!-- F -->
          <li v-if="data.an_item_f_metatype && data.an_item_f_metatype > 0">
            <div class="left">
              <!-- 回显状态 -->
              <!-- <button>F</button> -->
              F.
              <p v-if="data.an_item_f_metatype == 1">
                {{ data.an_item_f_content }}
              </p>
              <p
                v-if="data.an_item_f_metatype == 2"
                v-html="data.an_item_f_content"
              ></p>
              <img
                v-show="data.an_item_f_metatype == 3"
                :src="data.an_item_f_content"
                alt=""
              />
            </div>
            <a
              class="right"
              :href="data.an_item_f_content"
              target="_blank"
              v-show="data.an_item_f_metatype == 3"
            >
              <img src="../../assets/img/yulan.png" alt="" />
              大图预览
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 配伍题题目选项 -->
    <div class="radio_c_c">
      <div
        class="c_box"
        v-for="(c, i) in data.qu_list"
        :key="i"
        :id="type == 2 ? c.isNum + 1 : i"
      >
        <div v-if="c.qu_metatype == 1">
          {{ type == 2 ? c.isNum + 1 : i + 1 }}.
          {{ c.qu_content }}
        </div>
        <div style="display: flex" v-if="c.qu_metatype == 2">
        {{ type == 2 ? c.isNum + 1 : i + 1 }}.
          <p style="max-width:1050px;" v-html="c.qu_content"></p>
        </div>
        <div style="display: flex" v-if="c.qu_metatype == 3">
          {{ type == 2 ? c.isNum + 1 : i + 1 }}.
          <img style="max-width:1050px;" :src="c.qu_content" alt="" />
        </div>
        <ul>
          <!-- A -->
          <li
            v-show="data.an_item_a_metatype"
            @click="pw_click('A', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('A') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'A' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'A' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('A') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'A' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'A' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            A
          </li>
          <!-- B -->
          <li
            v-show="data.an_item_b_metatype"
            @click="pw_click('B', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('B') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'B' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'B' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('B') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'B' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'B' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            B
          </li>
          <!-- C -->
          <li
            v-show="data.an_item_c_metatype"
            @click="pw_click('C', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('C') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'C' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'C' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('C') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'C' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'C' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            C
          </li>
          <!-- D -->
          <li
            v-show="data.an_item_d_metatype"
            @click="pw_click('D', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('D') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'D' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'D' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('D') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'D' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'D' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            D
          </li>
          <!-- E -->
          <li
            v-show="data.an_item_e_metatype"
            @click="pw_click('E', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('E') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'E' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'E' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('E') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'E' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'E' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            E
          </li>
          <!-- F -->
          <li
            v-show="data.an_item_f_metatype"
            @click="pw_click('F', i)"
            :class="
              type == 2
                ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('F') > -1
                  ? 'd_x_x_z'
                  : ''
                : c.user_answer == 'F' && c.user_answer != c.answer
                ? 'red'
                : c.answer == 'F' && c.user_answer != ''
                ? 'yes'
                : ''
            "
          >
            <!-- 回显状态 -->
            <i
              :class="
                type == 2
                  ? paper[c.isNum + 1] && paper[c.isNum + 1].indexOf('F') > -1
                    ? 'el-icon-check'
                    : ''
                  : c.user_answer == 'F' && c.user_answer != c.answer
                  ? 'el-icon-close'
                  : c.answer == 'F' && c.user_answer != ''
                  ? 'el-icon-check'
                  : ''
              "
            ></i>
            F
          </li>
        </ul>
        <div class="x" v-if="c.user_answer != '' && type != 2"></div>
        <div class="analysis_box" v-if="c.user_answer != '' && type != 2">
          <div class="top">
            <div class="left">
              <span>正确答案: {{ c.answer }}</span>
              <span v-show="c.answer == c.user_answer && c.user_answer != ''">
                您的答案:&nbsp;
                <p style="margin-right: 20px">
                  {{ c.user_answer }}
                </p>
                <img
                  class="dui_icon"
                  src="../../assets/img/dui_answer.png"
                  alt=""
                />
              </span>
              <span
                class="wrong_answer"
                v-show="c.answer != c.user_answer || c.user_answer == ''"
              >
                您的答案:&nbsp;
                <p style="margin-right: 20px">
                  {{ c.user_answer }}
                </p>
                <img src="../../assets/img/wrong.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 答案解析------------------------------------------------- -->
    <div class="analysis_box pw">
      <div
        class="down"
        v-show="!isReveal && is_2_show && type != 2"
        style="border: none"
      >
        <div class="down_content">
          <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
          <div>答案解析:</div>
          <div v-if="data.an_detail_metatype == 1">
            {{ data.an_detail_content }}
          </div>
          <p
            v-html="data.an_detail_content"
            v-else-if="data.an_detail_metatype == 2"
          ></p>
          <img :src="data.an_detail_metatype" alt="" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question_2",
  props: {
    is_num: Number, //对应题数
    data: Object, //问题数据
    type: Number, //1答题   2 试卷
    isReveal: Boolean, //答案解析状态
    is_2_show: Boolean,
  },
  data() {
    return {
      paper: [], //答案
    };
  },
  watch: {
      is_show(val){
          console.log(val)
      }
  },
  computed: {},
  created() {
    if (this.type == 2) {
      this.paper = this.$store.state.paper;
    }
  },
  mounted() {
  },
  methods: {
    //配伍 材料 选择 2
    pw_click(val, i) {
      switch (this.type) {
        case 1:
          if (this.data.qu_list[i].user_answer == "") {
            this.data.qu_list[i].user_answer = val;
            if (
              this.data.qu_list[i].user_answer == this.data.qu_list[i].answer
            ) {
              this.data.qu_list[i].is_YN = 1;
            } else {
              this.data.qu_list[i].is_YN = 2;
            }
            let a = this.data.qu_list.find((el) => {
              return el.user_answer == "";
            });
            this.$emit("is_Tshow", a ? false : true);
          }
          break;
        case 2:
          let paper = this.paper;
          paper[this.data.qu_list[i].isNum + 1] = val;
          this.$store.commit("PaperState", paper);
          this.paper = paper;
          this.$emit("isTNum", this.data.qu_list[i].isNum + 1);
          this.$forceUpdate();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.d_x_x_z {
  background: rgba(52, 107, 218, 0.1) !important;
  color: #3e7eff !important;
  border: 1px solid #3e7eff !important;
}
.d_x_x_z i {
  color: #fff !important;
  background: #3e7eff !important;
  border: none !important;
  width: 22px !important;
  height: 22px !important;
}
.c_box ul{
  position: relative;
  // padding-bottom: 30px;
}
.x{
  margin-top: 30px;
  display: block;
  width: 100%;
  height: 1px;
  background: #eee;
}
.radio_c_c .top{
  height: auto !important;
  padding: 30px 16px 0 !important;
}
</style>