<template>
        <div class="">
          <!-- 单选/多选/ -->
          <!-- 题目 / qu_metatype 1纯文字 2富文本 3图片链接-->
          <!-- 单选题 -->
          <div class="topic_box">
            <!-- 简答题 -->
            <div class="material_problem_box" :id="type == 2 ?  data.isNum + 1:is_num ">
              <!-- 材料 -->
              <div class="stuff_box" style="display: flex;flex-wrap: wrap;">
               {{ type == 2 ?  data.isNum + 1:is_num }}.
                <div v-show="data.case_metatype == 1">
                  {{ data.case_content }}
                </div>
                <p style="max-width: 1050px"
                  v-show="data.case_metatype == 2"
                  v-html="data.case_content"
                ></p>
                <img style="max-width: 1050px"
                  v-show="data.case_metatype == 3"
                  :src="data.case_content"
                  alt=""
                />
                <!-- <img v-show="item.case_metatype == 1">{{item.case_content}}</img> -->
              </div>
              <!-- 简答题文案 -->
              <div class="brief_answer">
                <p>简答题</p>
                <span>（主观题无固定答案，点击底部按钮即可查看答案）</span>
              </div>
              <!-- 问题 -->
              <div class="problem_content_box">
                <div v-show="data.qtype == 5" style="display: flex">
                  <!-- {{ index + 1 }}. -->
                  <p v-html="data.problem_content"></p>
                </div>
              </div>
            </div>
          </div>
          <!-- 材料分析答案解析 -->
          <div class="analysis_box" v-if="type != 2">
            <div
              class="down"
              v-show="!isReveal"
              style="border: none"
            >
              <div class="down_content">
                <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
                <div>答案解析:</div>
                <div v-if="data.an_detail_metatype == 1">
                  {{ data.an_detail_content }}
                </div>
                <p
                  v-html="data.an_detail_content"
                  v-else-if="data.an_detail_metatype == 2"
                ></p>
                <img :src="data.an_detail_metatype" alt="" v-else />
              </div>
            </div>
          </div>
        </div>
</template>

<script>
export default {
  name: "Question_5",
 props: {
    is_num: Number, //对应题数
    data: Object, //问题数据
    type: Number, //1答题   2 试卷
    isReveal: Boolean, //答案解析状态
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>